export const readFromLocalStorage = (key) => {
  let result
  result = localStorage[key]
  if (result?.startsWith('{') || result?.startsWith('[')) {
    result = JSON.parse(result)
  }

  return result
}

export const writeToLocalStorage = (key, value) => {
  localStorage[key] = typeof value === 'string' ? value : JSON.stringify(value)
}
