/* eslint-disable react/state-in-constructor */
import React, { PureComponent, createContext } from 'react'
import { isDayTime } from '../utils/global'
import { writeToLocalStorage, readFromLocalStorage } from '../utils/localStorage'
import { noop } from '../utils/constants'

const defaultState = {
  dark: false,
  notFound: false,
  soundEnabled: true,
  toggleDark: noop,
  toggleSound: noop,
}

const ThemeContext = createContext(defaultState)

class ThemeProvider extends PureComponent {
  state = {
    dark: readFromLocalStorage('dark') || defaultState.dark,
    notFound: false,
    soundEnabled: readFromLocalStorage('soundEnabled') || defaultState.soundEnabled,
  }

  componentDidMount() {
    const isDark = JSON.parse(localStorage.getItem('dark'))
    const isSoundEnabled = JSON.parse(localStorage.getItem('soundEnabled'))

    // checking sound on cookie condition
    if (isSoundEnabled !== null) {
      this.setState({ soundEnabled: isSoundEnabled })
    }
    // checking theme on day time and cookie condition
    if (isDark === null) {
      this.setState({ dark: !isDayTime() })
    } else {
      this.setState({ dark: isDark })
    }
  }

  toggleDark = () => {
    this.setState(
      (prevState) => ({ dark: !prevState.dark }),
      () => writeToLocalStorage('dark', this.state.dark),
    )
  }

  toggleSound = () => {
    this.setState(
      (prevState) => ({ soundEnabled: !prevState.soundEnabled }),
      () => writeToLocalStorage('soundEnabled', this.state.soundEnabled),
    )
  }

  setNotFound = () => {
    this.setState({ notFound: true })
  }

  setFound = () => {
    this.setState({ notFound: false })
  }

  render() {
    const { children } = this.props
    const { dark, notFound, soundEnabled } = this.state

    return (
      <ThemeContext.Provider
        value={{
          dark,
          notFound,
          soundEnabled,
          setFound: this.setFound,
          setNotFound: this.setNotFound,
          toggleDark: this.toggleDark,
          toggleSound: this.toggleSound,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
