import config from '../../data/SiteConfig'
import { HOUR_FROM_TIME_OF_DAY } from '../configs/time.config'
import { format, parseISO } from 'date-fns'

function formatDate(date, dateFormat = config.dateFormat) {
  const parsedDate = parseISO(date)
  return format(parsedDate, dateFormat)
}

function getCurrentHours() {
  return new Date().getHours()
}

function isDayTime() {
  const hourOfTheDay = getCurrentHours()
  // 7am to 6:59pm
  return hourOfTheDay > HOUR_FROM_TIME_OF_DAY.morning && hourOfTheDay < HOUR_FROM_TIME_OF_DAY.night
}

export { formatDate, isDayTime }
